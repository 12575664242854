import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import Layout from '../../components/Layout';
import Image from '../../components/Image';
import PageHelmet from '../../components/PageHelmet';
import CustomModal from '../../components/CustomModal';
import { HTMLContent } from '../../components/Content';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const ContactPageTemplate = ({
  pageTitle,
  title,
  image,
  address,
  contact,
  seoObject,
  iframes,
  socialTitle,
  helmet,
}) => {
  const [isPMModalOpen, setPMModalStatus] = useState(false);
  const [isAMModalOpen, setAMModalStatus] = useState(false);
  const [isREModalOpen, setREModalStatus] = useState(false);
  const [isHTModalOpen, setHTModalStatus] = useState(false);

  const togglePMModalStatus = () => {
    if (isPMModalOpen) {
      return setPMModalStatus(false);
    }
    return setPMModalStatus(true);
  };
  const toggleAMModalStatus = () => {
    if (isAMModalOpen) {
      return setAMModalStatus(false);
    }
    return setAMModalStatus(true);
  };
  const toggleREModalStatus = () => {
    if (isREModalOpen) {
      return setREModalStatus(false);
    }
    return setREModalStatus(true);
  };
  const toggleHTModalStatus = () => {
    if (isHTModalOpen) {
      return setHTModalStatus(false);
    }
    return setHTModalStatus(true);
  };
    
  return (
    <div className='contact-page-container container content'>
      {helmet || ''}
      <PageHelmet seoObject={seoObject} />
      <h1 className='title contact-page-title'>{pageTitle}</h1>
      <div className='columns'>
        <div className='column is-1'></div>
        <div className='column is-5'>
          <Image
            imageInfo={{ image }}
            style={{ width: '90%' }}
            classes='contact-image'
          />
        </div>

        <div className='column is-5'>
          <hr className='hr-black' />
          <div className='contact-page-text'>{title}</div>
          <div className='contact-buttons-section'>
            <CustomModal isOpen={isREModalOpen} onClose={toggleREModalStatus}>
              <div className='contact-modal-container'>
                <HTMLContent content={iframes.iframe_realestate} />
              </div>
            </CustomModal>
            <div
              className='btn btn-main contact-button contact-button-re'
              onClick={toggleREModalStatus}>
              房地产销售部
            </div>
            <CustomModal isOpen={isPMModalOpen} onClose={togglePMModalStatus}>
              <div className='contact-modal-container'>
                <HTMLContent content={iframes.iframe_project} />
              </div>
            </CustomModal>
            <div
              className='btn btn-main contact-button contact-button-pm'
              onClick={togglePMModalStatus}>
              项目管理部
            </div>
            <CustomModal isOpen={isAMModalOpen} onClose={toggleAMModalStatus}>
              <div className='contact-modal-container'>
                <HTMLContent content={iframes.iframe_asset} />
              </div>
            </CustomModal>
            <div
              className='btn btn-main contact-button contact-button-am'
              onClick={toggleAMModalStatus}>
              资产管理部
            </div>

            <CustomModal isOpen={isHTModalOpen} onClose={toggleHTModalStatus}>
              <div className='contact-modal-container'>
              <HTMLContent content={iframes.iframe_hospitality} />
              </div>
            </CustomModal>
            <div
              className='btn btn-main contact-button contact-button-ht'
              onClick={toggleHTModalStatus}>
              酒店管理部
            </div>            
          </div>
          <div className='columns contact-information'>
            <div className='column is-6'>
              <h3 className='text-header'>{address.addressTitle}</h3>
              <div>{address.address}</div>
              <h3 className='text-header'>{socialTitle}</h3>
              <div className='social'>
                <a
                  title='facebook'
                  rel='noopener noreferrer'
                  target='_blank'
                  href='https://www.facebook.com/hokkaidotracks'>
                  <FaFacebook style={{ color: 'black', fontSize: '30px' }} />
                </a>
                <a
                  title='linkedin'
                  rel='noopener noreferrer'
                  target='_blank'
                  href='https://www.linkedin.com/company/hokkaido-tracks-resort-properties'>
                  <FaLinkedin style={{ color: 'black', fontSize: '30px' }} />
                </a>
                <a
                  title='instagram'
                  rel='noopener noreferrer'
                  target='_blank'
                  href='https://instagram.com/hokkaido_tracks/'>
                  <FaInstagram style={{ color: 'black', fontSize: '30px' }} />
                </a>
              </div>
            </div>
            <div className='column is-6'>
              <h3 className='text-header'>{pageTitle}</h3>
              <div>
                <a href={`tel:${contact.phone}`}>{contact.phone}</a>
              </div>
              <div>
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </div>
            </div>
          </div>
        </div>
        <div className='column is-1'></div>
      </div>
    </div>
  );
};

const ContactPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <ContactPageTemplate
        location={location}
        title={frontmatter.contactTitle}
        image={frontmatter.contactImage}
        pageTitle={frontmatter.pageTitle}
        seoObject={frontmatter.seoObject}
        address={frontmatter.contactAddress}
        contact={frontmatter.contactItems}
        iframes={frontmatter.iframes}
        socialTitle={frontmatter.socialTitle}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.contactImage}`}
          />
        }
      />
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageQueryZHHANS {
    markdownRemark(
      frontmatter: { templateKey: { eq: "contact-page-zhhans" } }
    ) {
      frontmatter {
        pageTitle
        contactTitle
        iframes {
          iframe_project
          iframe_asset
          iframe_realestate
          iframe_hospitality
        }
        contactImage
        contactAddress {
          addressTitle
          address
        }
        contactItems {
          phone
          email
        }
        socialTitle
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
